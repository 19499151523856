import React, { memo } from "react";

const Particle = memo((props) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="27px"
      viewBox="0 0 405.333 405.333"
      fill="#c3c3c3"
    >
      <g>
        <g>
          <g>
            <path
              d="M10.667,234.667C4.8,234.667,0,239.467,0,245.333C0,251.2,4.8,256,10.667,256c5.867,0,10.667-4.8,10.667-10.667
				C21.333,239.467,16.533,234.667,10.667,234.667z"
            />
            <path
              d="M10.667,149.333C4.8,149.333,0,154.133,0,160c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667
				C21.333,154.133,16.533,149.333,10.667,149.333z"
            />
            <path
              d="M160,384c-5.867,0-10.667,4.8-10.667,10.667c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667
				C170.667,388.8,165.867,384,160,384z"
            />
            <path d="M74.667,53.333c-11.733,0-21.333,9.6-21.333,21.333S62.933,96,74.667,96S96,86.4,96,74.667S86.4,53.333,74.667,53.333z" />
            <path
              d="M74.667,138.667c-11.733,0-21.333,9.6-21.333,21.333s9.6,21.333,21.333,21.333S96,171.733,96,160
				S86.4,138.667,74.667,138.667z"
            />
            <path
              d="M74.667,224c-11.733,0-21.333,9.6-21.333,21.333c0,11.733,9.6,21.333,21.333,21.333S96,257.067,96,245.333
				C96,233.6,86.4,224,74.667,224z"
            />
            <path
              d="M394.667,170.667c5.867,0,10.667-4.8,10.667-10.667c0-5.867-4.8-10.667-10.667-10.667c-5.867,0-10.667,4.8-10.667,10.667
				C384,165.867,388.8,170.667,394.667,170.667z"
            />
            <path
              d="M245.333,21.333c5.867,0,10.667-4.8,10.667-10.667C256,4.8,251.2,0,245.333,0c-5.867,0-10.667,4.8-10.667,10.667
				C234.667,16.533,239.467,21.333,245.333,21.333z"
            />
            <path d="M160,96c11.733,0,21.333-9.6,21.333-21.333s-9.6-21.333-21.333-21.333s-21.333,9.6-21.333,21.333S148.267,96,160,96z" />
            <path
              d="M160,21.333c5.867,0,10.667-4.8,10.667-10.667C170.667,4.8,165.867,0,160,0c-5.867,0-10.667,4.8-10.667,10.667
				C149.333,16.533,154.133,21.333,160,21.333z"
            />
            <path
              d="M245.333,96c11.733,0,21.333-9.6,21.333-21.333s-9.6-21.333-21.333-21.333c-11.733,0-21.333,9.6-21.333,21.333
				S233.6,96,245.333,96z"
            />
            <path
              d="M74.667,309.333c-11.733,0-21.333,9.6-21.333,21.333c0,11.733,9.6,21.333,21.333,21.333S96,342.4,96,330.667
				C96,318.933,86.4,309.333,74.667,309.333z"
            />
            <path d="M245.333,128c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S263.04,128,245.333,128z" />
            <path
              d="M330.667,224c-11.733,0-21.333,9.6-21.333,21.333c0,11.733,9.6,21.333,21.333,21.333c11.733,0,21.333-9.6,21.333-21.333
				C352,233.6,342.4,224,330.667,224z"
            />
            <path
              d="M330.667,309.333c-11.733,0-21.333,9.6-21.333,21.333c0,11.733,9.6,21.333,21.333,21.333
				C342.4,352,352,342.4,352,330.667C352,318.933,342.4,309.333,330.667,309.333z"
            />
            <path
              d="M330.667,138.667c-11.733,0-21.333,9.6-21.333,21.333s9.6,21.333,21.333,21.333c11.733,0,21.333-9.6,21.333-21.333
				S342.4,138.667,330.667,138.667z"
            />
            <path
              d="M394.667,234.667c-5.867,0-10.667,4.8-10.667,10.667C384,251.2,388.8,256,394.667,256c5.867,0,10.667-4.8,10.667-10.667
				C405.333,239.467,400.533,234.667,394.667,234.667z"
            />
            <path
              d="M330.667,53.333c-11.733,0-21.333,9.6-21.333,21.333S318.933,96,330.667,96C342.4,96,352,86.4,352,74.667
				S342.4,53.333,330.667,53.333z"
            />
            <path d="M160,213.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S177.707,213.333,160,213.333z" />
            <path
              d="M160,309.333c-11.733,0-21.333,9.6-21.333,21.333c0,11.733,9.6,21.333,21.333,21.333s21.333-9.6,21.333-21.333
				C181.333,318.933,171.733,309.333,160,309.333z"
            />
            <path d="M160,128c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S177.707,128,160,128z" />
            <path
              d="M245.333,309.333c-11.733,0-21.333,9.6-21.333,21.333C224,342.4,233.6,352,245.333,352
				c11.733,0,21.333-9.6,21.333-21.333C266.667,318.933,257.067,309.333,245.333,309.333z"
            />
            <path
              d="M245.333,384c-5.867,0-10.667,4.8-10.667,10.667c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667
				C256,388.8,251.2,384,245.333,384z"
            />
            <path d="M245.333,213.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S263.04,213.333,245.333,213.333z" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
});

export default Particle;
